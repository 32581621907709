/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require('react');
const PagesWrapper = require('./src/components/layouts/pagesWrapper').default;

exports.wrapPageElement = ({ element, props }) => {
  return <PagesWrapper {...props}>{element}</PagesWrapper>;
};
