// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-pages-page-js": () => import("./../src/templates/pages/page.js" /* webpackChunkName: "component---src-templates-pages-page-js" */),
  "component---src-templates-posts-category-js": () => import("./../src/templates/posts/category.js" /* webpackChunkName: "component---src-templates-posts-category-js" */),
  "component---src-templates-posts-works-js": () => import("./../src/templates/posts/works.js" /* webpackChunkName: "component---src-templates-posts-works-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

