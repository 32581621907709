import React, { Fragment, useContext, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  BrowserView,
  isMobile,
  isTablet,
  MobileView,
} from 'react-device-detect';
import { PageDetailsContext } from '../layouts/pagesWrapper';
import './main-menu.scss';

function MainMenu() {
  const [menuOpened, toggleOpenMenu] = useState(false);
  const [wrapperRef, setWrapperRef] = useState(null);

  const { isClient } = useContext(PageDetailsContext);

  useEffect(() => {
    if (isClient && (isMobile || isTablet)) {
      const menuHamburger = document.querySelector('#menu-button');
      const header = document.querySelector('header');
      document.addEventListener('mousedown', handleClickOutside);
      window.onscroll = () => {
        if (
          window.pageYOffset >=
          header.clientHeight - menuHamburger.clientHeight - 30
        ) {
          menuHamburger.classList.add('fixed');
        }
        if (window.pageYOffset === 0) {
          menuHamburger.classList.remove('fixed');
        }
      };
    }
  });

  const handleClickOutside = (event) => {
    if (
      event.target.id !== 'menu-button' &&
      wrapperRef &&
      !wrapperRef.contains(event.target)
    ) {
      toggleOpenMenu(false);
    }
  };

  const handleWrapperRef = (node) => {
    setWrapperRef(node);
  };

  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query WPMenus {
        allWordpressWpApiMenusMenusItems(
          filter: { name: { eq: "Main Menu" } }
        ) {
          edges {
            node {
              id
              name
              slug
              items {
                title
                target
                type
                url
                object_slug
              }
            }
          }
        }
      }
    `,
  );

  return (
    <Fragment>
      {isClient && (
        <nav>
          <MobileView>
            <div
              id='menu-button'
              className={'navigation__mobile--button'}
              onClick={() => toggleOpenMenu(!menuOpened)}
            >
              <span />
            </div>
            <div className={'navigation__mobile'}>
              <ul
                id='mobile-menu-list'
                className={`navigation__mobile--list ${
                  menuOpened ? 'active' : ''
                }`}
              >
                <div ref={handleWrapperRef}>
                  {allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                    (item) => (
                      <li key={item.wordpress_id} className={'list-item'}>
                        <Link
                          key={item.wordpress_id}
                          className={'link'}
                          to={
                            item.type === 'custom'
                              ? item.url.replace('/', '')
                              : item.object_slug
                          }
                          state={{ parentMenu: '' }}
                          getProps={setActiveMenuWithSubmenu}
                          onClick={() => toggleOpenMenu(false)}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ),
                  )}
                </div>
              </ul>
            </div>
          </MobileView>
          <BrowserView>
            <div className={'navigation'}>
              <ul className={'navigation__list'}>
                {allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                  (item) => (
                    <li
                      key={item.wordpress_id}
                      className={'navigation__list--item'}
                    >
                      <Link
                        key={item.wordpress_id}
                        className={'link'}
                        to={
                          item.type === 'custom'
                            ? item.url.replace('/', '')
                            : item.object_slug
                        }
                        state={{ parentMenu: '' }}
                        getProps={setActiveMenuWithSubmenu}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </BrowserView>
        </nav>
      )}
    </Fragment>
  );
}

const setActiveMenuWithSubmenu = (item) => {
  if (item.location.state) {
    if (item.location.state.parentMenu === 'Alchemy Research') {
      item.href === '/post/introduction'
        ? (item.isCurrent = true)
        : (item.isCurrent = false);
    }
  }
  return item.isCurrent || item.isPartiallyCurrent
    ? { className: 'link active' }
    : null;
};

export default MainMenu;
