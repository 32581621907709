import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import SiteTitle from '../site-title/SiteTitle';
import './header.scss';
import MainMenu from '../main-menu/MainMenu';

const Header = () => (
  <header>
    <Fragment>
      <Link className={'header-link'} to='/'>
        <SiteTitle />
      </Link>
    </Fragment>
    <MainMenu />
  </header>
);

export default Header;
