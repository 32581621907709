import React, { createContext, useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';

export const PageDetailsContext = createContext();

function PagesWrapper({ children }) {
  const [isClient, setIsClient] = useState(false);
  const [isContactPage, setIsContactPage] = useState(false);
  const [isAudioOnPage, setIsAudioOnPage] = useState(false);

  useEffect(() => {
    typeof window !== 'undefined' && setIsClient(true);
    children.key === '/contact/'
      ? setIsContactPage(true)
      : setIsContactPage(false);
    document.querySelectorAll('.wp-block-audio').length > 0
      ? setIsAudioOnPage(true)
      : setIsAudioOnPage(false);
  });

  return (
    <PageDetailsContext.Provider
      value={{ isClient, isContactPage, isAudioOnPage }}
    >
      <div className={'body'}>
        <div className={'container'}>
          <Header />
        </div>
        {children}
        <Footer />
      </div>
    </PageDetailsContext.Provider>
  );
}

export default PagesWrapper;
