import React from 'react';
import './footer.scss';

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const Footer = () => (
  <footer>
    <div className={'copyrights'}>
      <p className={'copyrights__text'}>
        {`Copyrights \u00A9 All rights reserved Yaroslaff Soltan 2006 - ${getCurrentYear()}`}
      </p>
    </div>
  </footer>
);

export default Footer;
