import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { isMobile, isTablet } from 'react-device-detect';
import { PageDetailsContext } from '../layouts/pagesWrapper';
import './site-title.scss';

const SiteTitle = () => {
  const { isClient } = useContext(PageDetailsContext);
  const { allWordpressSiteMetadata } = useStaticQuery(graphql`
    {
      allWordpressSiteMetadata {
        edges {
          node {
            name
          }
        }
      }
    }
  `);
  return (
    isClient && (
      <h1 className={`site-title ${isMobile || isTablet ? 'mobile' : ''}`}>
        {allWordpressSiteMetadata.edges[0].node.name}
      </h1>
    )
  );
};

export default SiteTitle;
